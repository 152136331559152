<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <!-- <div class="ic-title">新建后期服务</div> -->
      <a-row>
        <a-col :span="12">
          <a-form-item label="订单号" name="orderNumber">
            <a-input v-model:value.trim="form.orderNumber" :disabled="true" placeholder="输入订单号"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客户姓名" name="customerName">
            <a-input v-model:value.trim="form.customerName" :disabled="true" placeholder="输入客户姓名"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="签约时间" name="signingTime" :rules="[{ required: true, message: '请选择签约时间' }]">
            <a-date-picker :disabled="true" v-model:value="form.signingTime" placeholder="请选择签约时间" style="width: 100%"
              :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="订单金额" name="orderAmount">
            <a-input v-model:value.trim="form.orderAmount" :disabled="true" placeholder="输入订单金额"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="已付金额" name="paidAmount">
            <a-input v-model:value.trim="form.paidAmount" :disabled="true" placeholder="输入已付金额"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="订单归属人" name="attributablePerson">
            <a-input v-model:value.trim="form.attributablePerson" :disabled="true" placeholder="输入订单归属人"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="订单跟进人" name="followUpPerson">
            <a-input v-model:value.trim="form.followUpPerson" :disabled="true" placeholder="输入订单跟进人"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="服务项目" name="serviceItem">
            <a-input v-model:value.trim="form.serviceItem" :disabled="true" placeholder="输入服务项目"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="订单情况" name="orderStatus">
            <a-textarea v-model:value="form.orderStatus" :disabled="true" placeholder="请输入订单情况" style="width: 100%;"
              :rows="2" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="申请人姓名" name="applicantName">
            <a-input v-model:value.trim="form.applicantName" placeholder="输入申请人姓名"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="结束时间" name="endYear" :rules="[{ required: true, message: '请选择结束时间' }]">
            <a-date-picker picker="year" v-model:value="form.endYear" placeholder="请选择结束时间" style="width: 100%"
              :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="季节" name="endSeasonName" :rules="[{ required: true, message: '请选择季节' }]">
            <a-select v-model:value="form.endSeasonName" placeholder="请选择季节" style="width: 100%">
              <a-select-option key="春季" name="春季">春季</a-select-option>
              <a-select-option key="秋季" name="秋季">秋季</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="备注" name="remark">
            <a-textarea v-model:value="form.remark" placeholder="请输入备注" :rows="2" style="width: 100%;" />
          </a-form-item>
        </a-col>
      </a-row>
      <h3 style="margin-bottom: 20px;">申请项目</h3>
      <div v-for="(item, index) in form.applications">
        <div style="margin-bottom: 10px; display: flex; align-items: center;">
          <span>项目 {{ index + 1 }}</span>
          <DeleteOutlined @click="deleteItem(index)" v-if="index > 0" style="margin-left: 10px; cursor: pointer;" />
        </div>
        <a-row>
          <a-col :span="12">
            <a-form-item label="项目名称" :name="'name' + index">
              <a-input v-model:value.trim="form.applications[index].name" placeholder="输入项目"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="申请阶段" :name="'stage' + index">
              <a-select v-model:value="form.applications[index].stage" placeholder="请选择申请阶段" style="width: 100%">
                <a-select-option key="资料准备" name="资料准备">资料准备</a-select-option>
                <a-select-option key="提交网申" name="提交网申">提交网申</a-select-option>
                <a-select-option key="成绩寄送" name="成绩寄送">成绩寄送</a-select-option>
                <a-select-option key="等待录取" name="等待录取">等待录取</a-select-option>
                <a-select-option key="已录取" name="已录取">已录取</a-select-option>
                <a-select-option key="辅助入学" name="辅助入学">辅助入学</a-select-option>
                <a-select-option key="已完结" name="已完结">已完结</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="项目备注" :name="'remark' + index">
              <a-textarea v-model:value="form.applications[index].remark" placeholder="请输入项目备注" :rows="2"
                style="width: 100%;" />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <a-button @click="add" type="primary" ghost style="margin-bottom: 30px;">+ 新增申请项目</a-button>

      <div style="display: flex; align-items: center; margin-bottom: 20px;">
        <h3 style="">标化成绩</h3>
        <a-popover>
          <template #content>
            <p>订单管理新增语言考试订单后，会自动添加标化成绩数据</p>
          </template>
          <question-circle-outlined style="margin-left: 6px; color: #999999;" />
        </a-popover>
      </div>
      <div v-for="(item, index) in form.achievements">
        <a-row>
          <a-col :span="12">
            <a-form-item :label="'项目' + (index + 1)" :name="'name' + index">
              <a-input v-model:value.trim="form.achievements[index].name" placeholder="输入项目"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="项目备注" :name="'remark' + index">
              <a-input v-model:value="form.achievements[index].remark" placeholder="请输入项目备注" :rows="2"
                style="width: 100%;" />
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <a-form-item>
        <a-button type="primary" @click="submit" :loading="submitLoading">确认编辑</a-button>
      </a-form-item>
    </a-form>
    <a-modal v-model:open="openVerifyModal" title="提示">
      <p style="padding: 40px 0;">存在申请阶段为已完结的申请项目，对申请人的服务会变更为完结，确定吗？</p>
      <template #footer>
        <a-button @click="openVerifyModal = false">取消</a-button>
        <a-button type="primary" @click="verifyOk" :loading="submitLoading">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import { citysMenus } from '@/city.js';
import { companyEntity, country, receptionCity } from '@/utils/public';
import dayjs from 'dayjs';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: { endSeasonName: '春季' },
      openVerifyModal: false
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)
  },
  mounted() {

  },
  methods: {

    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/institutions/aftermarket/edit/get", { id: id })
        if (res.code == '200') {
          this.form = res.source;
          if (typeof res.source.signingTime === 'string') {
            this.form.signingTime = dayjs(res.source.signingTime);
          }
          if (res.source.endYear) {
            this.form.endYear = dayjs(res.source.endYear);
          }

          if (this.form.applications.length === 0) {
            this.form.applications = [
              {
                name: '',
                stage: '资料准备',
                remark: '',
                id: ''
              }
            ]
          }
        }
      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      let hasKey = this.form.applications.some(item => item.stage == '已完结');
      if (!hasKey) {
        this.submitLoading = true;
        let signTime = dayjs(this.form.signingTime).format('YYYY-MM-DD');
        let { applicantName, endYear, endSeasonName, remark, applications, achievements } = this.form;
        endYear = dayjs(this.form.endYear).format('YYYY');
        applications = JSON.stringify(applications);
        achievements = JSON.stringify(achievements);
        try {
          await this.$http.post('/institutions/aftermarket/edit', { id: this.selectedRows[0].id, applicantName, endYear, endSeasonName, remark, applications, achievements });
          this.$message.success('操作成功');
          this.$refs.form.resetFields();
          this.$emit('close');
        } catch ({ message }) {
          this.$message.error(message);
        }
        this.submitLoading = false;
      } else {
        this.openVerifyModal = true
      }
    },

    async verifyOk() {
      this.submitLoading = true;
      let signTime = dayjs(this.form.signingTime).format('YYYY-MM-DD');
      let { applicantName, endYear, endSeasonName, remark, applications, achievements } = this.form;
      endYear = dayjs(this.form.endYear).format('YYYY');
      applications = JSON.stringify(applications);
      achievements = JSON.stringify(achievements);
      try {
        await this.$http.post('/institutions/aftermarket/edit', { id: this.selectedRows[0].id, applicantName, endYear, endSeasonName, remark, applications, achievements });
        this.$message.success('操作成功');
        this.$refs.form.resetFields();
        this.$emit('close');
        this.openVerifyModal = false;
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    add() {
      if (this.form.applications.length > 19) {
        this.$message.error('申请项目数量最多20条');
        return
      }
      this.form.applications.push(
        {
          name: '',
          stage: '资料准备',
          remark: '',
          id: ''
        }
      )
    },

    deleteItem(index) {
      this.form.applications.splice(index, 1)
    }

  }
}
</script>

<style scoped lang="scss">
.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}
</style>
